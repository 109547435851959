<template>
<span>
    <v-layout mb-4>
        <v-flex xs10 ml-3>
            <h2 class="lime--text lighten-1 pt-3">Gerenciar Medidas - Edição</h2>
        </v-flex>
        <v-flex xs2 mr-3 text-right>
            <v-btn color="blue-grey" class="black--text" :to="{name: 'product-measures'}">
                <v-icon dark>keyboard_arrow_left</v-icon>
                Voltar
            </v-btn>
        </v-flex>
    </v-layout>

    <form-product-measure :productmeasure="productmeasure" :update="true"></form-product-measure>
</span>
</template>

<script>
import FormProductMeasureComponent from "./partial/FormProductMeasureComponent";

export default {
    name: "AddProductMeasuresComponent",
    created() {
        this.loadProductMeasure()
    },
    props: {
        prod_me_id: {
            require: true
        }
    },
    data() {
        return {
            productmeasure: {
                prod_me_id: '',
                prod_me_nome: '',
                prod_me_slug: '',
                prod_me_descricao: '',
                prod_me_logo: '',
                prod_me_banner: '',
                prod_me_status: false,
            },
            upload: null,
            fileName: 'Selecione uma foto',
            preview: null,
            langs: true
        }
    },
    methods: {
        loadProductMeasure() {
            this.$store.dispatch('loadProductMeasure', this.prod_me_id)
                .then(response => this.productmeasure = response)
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    },
    components: {
        formProductMeasure: FormProductMeasureComponent
    }
};
</script>

<style scoped>

</style>
